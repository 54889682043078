<template>
  <div @click="close()" :class="`stb_modal border-box px2 py2 ${showModal ? 'stb_modal_active' : ''}`">
    <div @click.stop=""
      class="content-wrapper max-width-3 col-12 p3 relative border-box bg-color-white overflow-y-scroll h100 no-scrollbar"
      style="border-radius: 8px">
      <!-- Close button -->
      <div @click="close()" class="absolute pointer p1 z2"
        style="width: 1.5rem; height: 1.5rem; background-color: white; border-radius: 999px; top: 0.75rem; right: 1rem">
        <svg style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" width="16" height="16"
          viewBox="0 0 16 16" fill="none">
          <g clip-path="url(#clip0_1922_48204)">
            <rect width="19.6867" height="2" rx="1"
              transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)" fill="black" />
            <rect x="15.4902" y="1.58594" width="19.6867" height="2" rx="1" transform="rotate(135 15.4902 1.58594)"
              fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_1922_48204">
              <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <!-- Slot for content -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Storefinder_Modal",
  props: {},
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    // this.setWindowHeight();
    // document.addEventListener("scroll", () => {
    //   this.setWindowHeight();
    // });
    // document.addEventListener("resize", () => {
    //   this.setWindowHeight();
    // });
  },
  methods: {
    open() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    setWindowHeight() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>
<style scoped></style>
