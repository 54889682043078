<template>
  <div class="map-flex flex-grow overflow-hidden col-12">
    <!-- mobile input part -->
    <div id="mobile-input" ref="mobile-input" class="lg-hide block p2 bg-color-11">
      <!-- shrinked version -->
      <div v-show="!expandMobileInputs" class="flex justify-between gap2 items-center"
        @click="expandMobileInputs = true">
        <p class="h5 color-14 open-sans-regular">{{ address }}</p>
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
          <path d="M8.99023 1.60596L5.00021 5.59598L1.01018 1.60596" stroke="#555D65" stroke-width="1.59601"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div v-show="expandMobileInputs" class="relative mb2">
        <svg @click="getStoresByAddress" class="bg-color-white px1"
          style="position: absolute; z-index: 1; top: 50%; right: 0.5rem; transform: translateY(-50%)" width="24"
          height="24" viewBox="0 0 24 24" fill="none">
          <rect width="2.2345" height="4.80648" rx="1.11725"
            transform="matrix(-0.711146 0.703044 -0.711146 -0.703044 22.4482 20.8848)" fill="#555D65" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.99 19.0461C15.5408 19.0461 19.2299 15.399 19.2299 10.9C19.2299 6.40104 15.5408 2.75391 10.99 2.75391C6.43916 2.75391 2.75 6.40104 2.75 10.9C2.75 15.399 6.43916 19.0461 10.99 19.0461Z"
            stroke="#555D65" stroke-width="1.5" />
        </svg>

        <input ref="address-input" type="text" class="rsf-input" v-model="address"
          :placeholder="$t('storefinder_placeholder_address')" />
      </div>
      <div v-if="expandMobileInputs" class="rsf-select-wrapper relative">
        <svg class="svg-color-14" :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24"
          viewBox="0 0 24 24" fill="none">
          <path d="M17 15L12 10L7 15" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <select @change="getStoresByAddress" v-model="radius" class="rsf-select"
          :placeholder="$t('storefinder_placeholder_perimeter')">
          <option v-for="(range, index) in perimeterOptions" :key="index" :value="range.km">{{ range.km }} {{
            $t("storefinder_unit_kilometers") }}</option>
        </select>
      </div>
    </div>
    <!-- desktop search bar & results -->
    <div class="hide lg-flex lg-flex-column gap3 col-12 max-width-2 border-box bg-color-11" style="max-width: 420px">
      <div class="vertical-overflow no-scrollbar">
        <!-- top part -->
        <div class="flex flex-column gap2 p3 bg-color-11" style="position: sticky; top: 0; z-index: 2">
          <h2 class="color-black open-sans-bold h2">
            {{ $t("storefinder_form_heading") }}
          </h2>
          <div class="relative">
            <svg @click="getStoresByAddress" class="bg-color-white px1"
              style="position: absolute; z-index: 3; top: 50%; right: 0.5rem; transform: translateY(-50%)" width="24"
              height="24" viewBox="0 0 24 24" fill="none">
              <rect width="2.2345" height="4.80648" rx="1.11725"
                transform="matrix(-0.711146 0.703044 -0.711146 -0.703044 22.4482 20.8848)" fill="#555D65" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.99 19.0461C15.5408 19.0461 19.2299 15.399 19.2299 10.9C19.2299 6.40104 15.5408 2.75391 10.99 2.75391C6.43916 2.75391 2.75 6.40104 2.75 10.9C2.75 15.399 6.43916 19.0461 10.99 19.0461Z"
                stroke="#555D65" stroke-width="1.5" />
            </svg>

            <input ref="address-input" type="text" class="rsf-input" v-model="address"
              :placeholder="$t('storefinder_placeholder_address')" />
          </div>
          <div class="rsf-select-wrapper relative">
            <svg class="svg-color-14" :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24"
              viewBox="0 0 24 24" fill="none">
              <path d="M17 15L12 10L7 15" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <select @change="getStoresByAddress" v-model="radius" class="rsf-select"
              :placeholder="$t('storefinder_placeholder_perimeter')">
              <option v-for="(range, index) in perimeterOptions" :key="index" :value="range.km">{{ range.km }} {{
                $t("storefinder_unit_kilometers") }}</option>
            </select>
          </div>
        </div>

        <div v-if="markers.length > 0" class="flex flex-column gap2 px3 pb3 mt1">
          <!-- TODO?: pagination when there are many stores -->
          <div v-for="(store, index) in markers" :key="index" @click="
            highlightStore(
              {
                lat: Number(store.mapCoordsLatitude),
                lng: Number(store.mapCoordsLongitude),
              },
              index,
              store
            )
            ">
            <StoreInfoSmall :position="{
              lat: Number(store.mapCoordsLatitude),
              lng: Number(store.mapCoordsLongitude),
            }" :userPosition="userPosition" :showArrow="true" :showStoreImage="true" :showDistance="true"
              :showOpeningTimes="false" :index="index" :selectedStore="selectedStore" :store="store" />
          </div>
        </div>
        <!-- initial no serachresults -->
        <div class="flex flex-column justify-center px3 border-box"
          v-if="markers.length == 0 && searchesMade == 0 && !isLoading">
          <p class="h2 open-sans-bold center mb2">No search results.</p>
          <p class="h4 open-sans-regular center">Type in your preferences and look for stores</p>
        </div>
        <!-- initial no serachresults -->
        <div class="flex flex-column justify-center px3 border-box"
          v-if="markers.length == 0 && searchesMade > 0 && !isLoading">
          <p class="h2 open-sans-bold center">No search results.</p>
          <p class="h4 open-sans-regular center">for your input.</p>
        </div>
        <!-- loading -->
        <div class="flex flex-column justify-center px3 border-box" v-if="isLoading">
          <div class="maps-loader mx-auto mb2"></div>
          <p class="h4 open-sans-regular center">Loading.</p>
        </div>
      </div>
    </div>

    <div class="relative col-12 flex-grow">
      <GoogleMaps :zoom="8" :center="{ lat: 52.52, lng: 13.405 }" :userPosition="userPosition"></GoogleMaps>

      <!-- StoreItem for Streetview -->
      <div v-if="selectedStore >= 0 && isStreetView"
        style="position: absolute; bottom: 3.5rem; left: 0.7rem; z-index: 2; max-width: 400px">
        <StoreInfoSmall :position="{
          lat: Number(markers[selectedStore].mapCoordsLatitude),
          lng: Number(markers[selectedStore].mapCoordsLongitude),
        }" :userPosition="userPosition" :showArrow="true" :showStoreImage="true" :showDistance="true"
          :showOpeningTimes="false" :index="index" :selectedStore="selectedStore" :store="markers[selectedStore]"
          :hasExitStreetViewBtn="true" :initEscapeStreetView="initEscapeStreetView" />
      </div>

      <!-- Loading -->
      <div v-show="isLoading" class="absolute p1 bg-color-white" style="top: 0.7rem; right: 0.7rem; border-radius: 6px">
        <div class="maps-loader"></div>
      </div>

      <!-- No Address Found -->
      <div v-show="addressNotFound" class="absolute col-12 h100 lg-p4 p2 border-box"
        :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`">
        <div class="bg-color-white col-12 h100 max-width-3 lg-p4 p2 border-box"
          style="border-radius: 8px; display: grid; place-items: center">
          <p class="h2 color-black open-sans-bold">
            {{ $t("storefinder_address_not_found") }}
          </p>
          <div @click="addressNotFound = false" class="rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto pointer">
            Ok</div>
        </div>
      </div>

      <!-- No Stores found -->
      <div v-show="markers.length <= 0 && searchesMade > 0 && !isLoading"
        class="absolute col-12 h100 lg-p4 p2 border-box"
        :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`">
        <div class="bg-color-white col-12 h100 max-width-3 lg-p4 p2 border-box"
          style="border-radius: 8px; display: grid; place-items: center">
          <p class="h2 color-black open-sans-bold">
            {{ $t("storefinder_no_stores_found") }}
          </p>
        </div>
      </div>

      <!-- Api Error -->
      <div v-show="apiError && !isLoading" class="absolute col-12 h100 lg-p4 p2 border-box"
        :style="`top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 99; display: grid; place-items: center; background-color: rgba(0,0,0,0.25);`">
        <div class="bg-color-white col-12 h100 max-width-3 lg-p4 p2 border-box"
          style="border-radius: 8px; display: grid; place-items: center">
          <p class="h2 color-black open-sans-bold center">
            {{ $t("storefinder_api_error") }}
          </p>
          <div @click="apiError = false" class="rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto pointer">Ok</div>
        </div>
      </div>
    </div>

    <!-- mobile store listing -->
    <Draggable>
      <div v-if="markers.length > 0"
        class="flex-grow overflow-y-scroll no-scrollbar p2 border-box gap2 flex flex-column">
        <div v-for="(store, index) in markers" :key="index" @click="
          highlightStore(
            {
              lat: Number(store.mapCoordsLatitude),
              lng: Number(store.mapCoordsLongitude),
            },
            index,
            store
          )
          ">
          <StoreInfoSmall :store="store" :position="{
            lat: Number(store.mapCoordsLatitude),
            lng: Number(store.mapCoordsLongitude),
          }" :userPosition="userPosition" :showArrow="true" :showStoreImage="true" :showAddress="false"
            :showOpeningTimes="true" :showDistance="true" :index="index" :selectedStore="selectedStore"
            :hasExitStreetViewBtn="false" />
        </div>
      </div>
    </Draggable>

    <Storefinder_Modal :ref="'contact-modal'">
      <ContactForm v-if="selectedStore >= 0" :store="markers[selectedStore]" :userPosition="userPosition" />
    </Storefinder_Modal>

    <Storefinder_Modal :ref="'mobile-details-modal'"> </Storefinder_Modal>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/event-bus";
import GoogleMaps from "./GoogleMaps.vue";
import Storefinder_Modal from "./Storefinder_Modal.vue";
import ContactForm from "./ContactForm.vue";
import StoreInfoSmall from "./StoreInfoSmall.vue";
import Draggable from "./Draggable.vue";

export default {
  name: "Storefinder",
  props: {
    siteLanguage: String,
  },
  components: {
    GoogleMaps: GoogleMaps,
    Storefinder_Modal: Storefinder_Modal,
    ContactForm: ContactForm,
    StoreInfoSmall: StoreInfoSmall,
    Draggable: Draggable,
  },
  data() {
    return {
      googleMapsLoaded: false,
      coords: {},
      userPosition: {},
      address: "",
      country: {},
      markers: [],
      radius: 250,
      isLoading: false,
      addressNotFound: false,
      selectedStore: -1,
      searchesMade: 0,
      expandMobileResults: false,
      expandMobileInputs: false,
      isStreetView: false,
      apiError: false,
      perimeterOptions: [
        { km: 50, miles: 50 },
        { km: 75, miles: 60 },
        { km: 100, miles: 70 },
        { km: 125, miles: 80 },
        { km: 150, miles: 90 },
        { km: 200, miles: 50 },
        { km: 250, miles: 50 },
        { km: 500, miles: 50 },
      ],
    };
  },
  created() {
    EventBus.$on("google_maps_loaded", () => {
      this.googleMapsLoaded = true;
      this.fetchCountry();
    });
    EventBus.$on("select_store", (position) => {
      this.selectStore(position);
    });
    EventBus.$on("add_streetview_panorama", () => {
      this.isStreetView = true;
    });
    EventBus.$on("escape_street_view", () => {
      this.isStreetView = false;
    });
    EventBus.$on("open_contact_modal", () => {
      this.$refs["contact-modal"].open();
    });
  },
  mounted() {
    // this.getStoresByAddress();
    document.addEventListener("keydown", (e) => {
      if (e.key != "Enter") {
        return;
      }
      if ((this.$refs["address-input"] === document.activeElement || this.$refs["address-input-mobile"] === document.activeElement) && this.address != "") {
        this.getStoresByAddress();
      }
    });
  },
  methods: {
    fetchCountry() {
      console.log("fetching country..");
      axios
        .get(`https://data.redseafish.com/public-api/storefinder/initstorefinder/${dfn_user_region}/json`)
        .then((response) => {
          this.address = response.data.countryname;
          if (response.data.countryfound) {
            this.getStoresByAddress();
          } else {
            this.address = this.$t("storefinder_type_in_address");
          }
        })
        .catch(() => { });
    },
    // getStoresByCountry() {
    //   this.isLoading = true;
    //   this.apiError = false;
    //   let that = this;
    //   let url = process.env.VUE_APP_RSF_API + "/public-api/storefinder/getstoresbycountry/json";
    //   axios
    //     .post(url, { isocode2: that.country.isocode2 })
    //     .then(function (response) {
    //       that.markers = response.data.mapPoints;
    //       EventBus.$emit("load_markers", that.markers);
    //       that.isLoading = false;
    //     })
    //     .catch(function () {
    //       that.isLoading = false;
    //       that.apiError;
    //     });
    // },
    getStoresByAddress() {
      if (this.address == "") {
        return;
      }
      this.expandMobileInputs = false;
      this.markers = [];
      this.userPosition = {};
      this.selectedStore = -1;
      this.isLoading = true;
      this.addressNotFound = false;
      this.isStreetView = false;
      this.apiError = false;
      let that = this;
      let url = process.env.VUE_APP_RSF_API + "/public-api/storefinder/getstoresbyaddress/json";
      EventBus.$emit("close_info_window");
      EventBus.$emit("shrink_draggable");
      axios
        .post(url, { address: that.address, radius: Number(this.radius) })
        .then(function (response) {
          that.isLoading = false;
          if (!response.data.addressFound) {
            that.addressNotFound = true;
            return;
          }
          let position = {
            lat: response.data.mapsViewport.centerLatitude,
            lng: response.data.mapsViewport.centerLongitude,
            zoom: response.data.mapsViewport.zoomLevel,
          };
          that.userPosition = position;
          that.markers = response.data.mapPoints;
          EventBus.$emit("load_markers", that.markers);
          EventBus.$emit("draw_circle", {
            position: position,
            radius: that.radius,
          });
          EventBus.$emit("go_to_point", position);
          that.searchesMade++;
        })
        .catch(function () {
          that.apiError = true;
        });
    },
    clearAllMarkers() {
      EventBus.$emit("clear_all_markers");
    },
    clearSingleMarker() {
      EventBus.$emit("clear_single_marker", 0);
    },
    initEscapeStreetView() {
      this.isStreetView = false;
      EventBus.$emit("escape_streetview");
    },
    highlightStore(position, index, store) {
      this.selectedStore = index;
      EventBus.$emit("highlight_marker", {
        position: position,
        store: store,
      });
      EventBus.$emit("add_info_window", {
        position: position,
        store: store,
      });
    },
    deHighlightStore() {
      this.selectedStore = -1;
      EventBus.$emit("dehighlight_marker");
    },
    selectStore(position) {
      for (let i = 0; i < this.markers.length; i++) {
        if (Number(this.markers[i].mapCoordsLatitude) == position.lat && Number(this.markers[i].mapCoordsLongitude) == position.lng) {
          this.selectedStore = i;
          EventBus.$emit("add_info_window", {
            position: position,
            store: this.markers[i],
          });
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.vertical-overflow {
  overflow-y: auto;
  box-sizing: border-box;
  flex: 1 1 auto;
  height: 1px;
  cursor: pointer;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 2px;
  /* Specified height */
  background: var(--rsf-2022-color-12);
  /* Grey background */
  outline: none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 999px;
  /* Slider handle height */
  background: var(--rsf-2022-color-1);
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 999px;
  /* Slider handle height */
  background: var(--rsf-2022-color-1);
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.btn-inactive {
  pointer-events: none;
  opacity: 0.5;
}

.map-flex {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .map-flex {
    flex-direction: column;
  }
}

/* HTML: <div class="loader"></div> */
.maps-loader {
  width: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid var(--rsf-2022-color-1);
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}
</style>
