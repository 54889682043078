import Storefinder from './components/Storefinder.vue'

export default [
    {
        path: '/:lang', 
        component: Storefinder,
    },
    {
        path: "*",
        redirect: "/en",
        component: Storefinder,
    }
]