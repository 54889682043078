<template>
  <div class="gridbox flex-grow">
    <div v-show="!apiSuccess && !isLoading && !apiFailure" :class="`gridbox-element`">
      <img class="col-12 cover mx-auto" style="margin-top: -2rem; margin-left: -2rem; max-height: 200px; width: calc(100% + 5rem);"
        :src="require('../assets/images/storefinder_listing_placeholder.jpg')" />
      <div class="mx-auto max-width-2 pt1 mb4">
        <div style="position:sticky; top: -2rem; background-color: white; z-index: 2">
        <StoreInfoSmall
            :name="store.name"
            :position="{ lat: Number(store.mapCoordsLatitude), lng: Number(store.mapCoordsLongitude) }"
            :userPosition="userPosition" 
            :showArrow="false"
            :showStoreImage="false"
            :showAddress="true"
            :showOpeningTimes="false"
            :showDistance="true"
          />
        </div>
        <div class="overflow-y-scroll no-scrollbar">
          <div>
          <h2 class="open-sans-bold h2 mt4">{{ $t('storefinder_contact_form_heading') }}</h2>
          <p class="open-sans-bold p color-black mt3">{{ $t('storefinder_label_firstname') }}</p>
          <input ref="address-input" type="text" class="rsf-input mtrf mbrf" v-model="firstname"
            :placeholder="$t('storefinder_placeholder_firstname')">
          <p class="open-sans-bold p color-black">{{ $t('storefinder_label_lastname') }}</p>
          <input ref="address-input" type="text" class="rsf-input mtrf mbrf" v-model="lastname"
            :placeholder="$t('storefinder_placeholder_lastname')">
          <p class="open-sans-bold p color-black">{{ $t('storefinder_label_email') }}</p>
          <input ref="address-input" type="email" class="rsf-input mtrf mbrf" v-model="email"
            :placeholder="$t('storefinder_placeholder_email')">
          <p class="open-sans-bold p color-black">{{ $t('storefinder_label_phone') }}</p>
          <input ref="address-input" type="text" class="rsf-input mtrf mbrf" v-model="phone"
            :placeholder="$t('storefinder_placeholder_phone')">
          <p class="open-sans-bold p color-black">{{ $t('storefinder_label_message') }}</p>
          <textarea ref="address-input" type="text" rows="4" class="rsf-textarea mtrf mbrf" v-model="message"
            :placeholder="$t('storefinder_placeholder_message')"></textarea>
          <div @click="sendForm" class="rsf-btn rsf-btn-primary bg-color-1 color-white mx-auto">
            {{ $t('storefinder_button_label_send') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isLoading" class="gridbox-element flex flex-column justify-center">
      <p class="open-sans-bold h2 center">Loading...</p>
    </div>
    <div v-show="apiSuccess" class="gridbox-element flex flex-column justify-center">
      <p class="open-sans-bold h2 center">Success</p>
    </div>
    <div v-show="apiFailure" class="gridbox-element flex flex-column justify-center">
      <p class="open-sans-bold h2 center">Fail</p>
    </div>
  </div>
</template>

<script>
import StoreInfoSmall from './StoreInfoSmall.vue';
export default {
  name: 'ContactForm',
  props: {
    store: Object, 
    userPosition: Object
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: '',
      isLoading: false,
      apiSuccess: false,
      apiFailure: false,
    }
  },
  components: {
    StoreInfoSmall: StoreInfoSmall
  },
  methods: {
    sendForm() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.apiSuccess = true;
      }, 3000);
    }
  }
}
</script>
<style scoped></style>