export function customInfoWindowLogic() {
  let myBtn = document.getElementsByClassName("gmiw-test");
  let btnId = document.getElementById("mytest-btn");

  //TODO find more stabel solution than a timeout
  setTimeout(() => {
    let infoWindowScrollContainer = document.getElementById(
      "info-window-horizontal-overflow-container"
    );
    let streetViewBtn = document.getElementById("streetview-btn");
    let closeBtn = document.getElementById("infowindow-close-btn");

    infoWindowScrollContainer.addEventListener("touchstart", (e) => {
      e.stopPropagation();
    });
    infoWindowScrollContainer.addEventListener("scroll", (e) => {
      e.stopPropagation();
    });
    closeBtn.addEventListener("click", () => {
      window.vueApp.$children[0].$children[0].deHighlightStore();
      window.vueApp.$children[0].$children[0].$children[0].infoWindow.close();
    });
    streetViewBtn.addEventListener("click", () => {
      window.vueApp.$children[0].$children[0].$children[0].addStreetViewPanorama(
        {
          lat: Number(streetViewBtn.getAttribute("data-lat")),
          lng: Number(streetViewBtn.getAttribute("data-lng")),
        }
      );
      window.vueApp.$children[0].$children[0].isStreetView = true;
    });
  }, 100);
}
