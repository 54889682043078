// TODO: fix transform translate shifting the box but keeps the unclickable zone of origin position
export function customInfoWindowConstruction(store, userPosition) {
  return `<div id="custom-info-window" style="max-width: 290px; width: 290px; box-shadow: 0 12.5px 10px 0 rgba(37, 44, 59, 0.07); border-radius: 8px; transform: translateY(calc(-50% - 2.7rem)) translateX(3rem); height: fit-content; pointer-events: all;" class="bg-color-white col-12 drop-shadow relative store-details-window">
  <style>div:has(>#custom-info-window){pointer-events: none;}</style>
  <div class="bg-color-white"
    style="border-radius: 5px; width: 2rem; height: 2rem; transform: translateY(-50%) rotate(45deg); position: absolute; top: 0; left: -0.8rem; top: 50%; z-index: -1">
  </div>
  <div id="infowindow-close-btn" class="bg-color-white relative pointer" style="border-radius: 999px; width: 24px; height: 24px; position: absolute; top: .5rem; right: .5rem; z-index: 99">
  <svg style="width: 12px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_1922_48204)">
          <rect
            width="19.6867"
            height="2"
            rx="1"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.15625 1.58594)"
            fill="black"
          />
          <rect
            x="15.4902"
            y="1.58594"
            width="19.6867"
            height="2"
            rx="1"
            transform="rotate(135 15.4902 1.58594)"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1922_48204">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="matrix(-1 0 0 1 16 0)"
            />
          </clipPath>
        </defs>
      </svg>
  </div>
  ${
    store.dealerDetails.images.storeThumbnail !== ""
      ? `<img 
      src="${store.dealerDetails.images.storeThumbnail}" 
      alt="Store thumbnail" 
      style="
        min-height: 170px;
        max-height: 170px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      " 
      class="cover col-12 bg-color-black max-width-3" 
    />`
      : ""
  }
  <div class="pb2 pt1">
    <p class="open-sans-bold h5 color-black px2">${store.dealerDetails.dealerName}</p>
    <p class="open-sans-regular h7 color-13 px2">${store.dealerDetails.address1}${store.dealerDetails.address2 ? " " + store.dealerDetails.address2 : ""}, ${store.dealerDetails.postal} ${store.dealerDetails.city}</p>
    <div class="flex items-center gap1 px2">
      <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect y="0.428955" width="11" height="14" fill="url(#pattern0_3434_705)" />
        <defs>
          <pattern id="pattern0_3434_705" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0_3434_705" transform="matrix(0.043771 0 0 0.0342556 -0.222222 -0.00461133)" />
          </pattern>
          <image id="image0_3434_705" width="33" height="32"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAYAAACcuBHKAAADF0lEQVRYCe1WTUtyURDut2S1UQoylb4XgtCykrCdEEYFFfSlGGERFBq1cVcoFm1ctCzdtWoT6SIoCKI2RVAQogVS4bw8A1fOteO9V99NQQMH5z7nzMzTnJkzNdAPkIYfwIF+J4lMJkMbGxs0ODhIbW1tZLfbaWpqinZ3d+nm5qauxBrOxP39PQ0NDVFjYyMHHx0dpVAoxGtkZIRaW1vJZDLR1tYWfX5+1kTGEInr62uyWq3kcDjo5OREGqBUKtHe3h41NzfTwMAA3d3dSc/JQF0SDw8P1NHRQU6nk56enmQ+VNjV1RX19vby+WKxqNqr9qFLwu12U1dXFz0/P1fz8Q2/vLzkq9nc3Py2JwM0SZyennINpNNple3b2xtFo1EaHx/nBR2YKNvb22x7cXEhwlJdk8TCwgJ1dnaqDF9fX6mnp4cDYA8LxQoMe4rkcjnG5+fnFajqryaJ/v5+WlpaUhlHIhF2nkqlyjh0EMGeKC6Xi3CdeqJJwmKxcMuJTiYmJrhL0A2KQG9vbyfsieL3+7moRUyma5KA49XVVZXd5OQkP1CVJPBoYU8UFKbZbBYhqa5JYnh4mLxer8pQdh3Hx8fS6xgbG6O+vj6VvexDk8Tc3Nw3Jyg+tCxqAEVps9lYryxMBMN7UXlFNZPAPECw29tblW2hUCi3KK4ALQpMlMfHR7bd398XYamumYmXlxd+hldWVqTGWuD6+jo1NTWp2rbaeU0SMFpcXCR0SeVfWs0h8Hw+zzbT09Nax8p7uiQwvHAlOzs7ZSM9JRwOsw3miBHRJQEnwWCQZ0E2m9X1eXZ2xgSMZgEODZHANMQU7e7upvf396pE0DkY+Zi6eLaNiiEScIb/mlpaWmhmZobEh0oJ9PHxQR6Phwv5/PxcgQ39GiYBb8lkklMdCARURL6+vsjn8/He0dGRocDioZpIwFB5OzAXkBGs2dlZJnB4eCj6NqzXTAKe8TihY5aXl2ltbY31RCJhOGjlwbpIwAmGE4hgxePxSr81fddNAlFisRgdHBzUFFB2+L9IyBzWg/2RULL2lwklE/8AlKk/jtRaHxYAAAAASUVORK5CYII=" />
        </defs>
      </svg>
      <p class="open-sans-regular color-13 h7">${Number(store.distance).toFixed(1)} km away</p>
    </div>
    <div id="info-window-horizontal-overflow-container" class="flex gap1 items-center mt2 px2 overflow-x-scroll no-scrollbar">
    ${
      store.dealerDetails.websiteURL != ""
        ? `<a href="${store.dealerDetails.websiteURL.includes("https://") ? store.dealerDetails.websiteURL : store.dealerDetails.websiteURL.includes("http://") ? store.dealerDetails.websiteURL : "https://" + store.dealerDetails.websiteURL}" rel="noreferrer noopener" target="_blank" class="rsf-btn btn-small rsf-btn-ghost color-1 h7 flex items-center gap1"
        style="padding: 0.5rem 0.75rem; font-size: 0.75rem">
        <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="svg-color-1" style="width: 1rem; height: 1rem; stroke: var(--rsf-2022-color-1)">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
</svg>

        Website
      </a>`
        : ""
    }
    ${
      store.dealerDetails.phoneNumber != ""
        ? `<a href="tel:${store.dealerDetails.phoneNumber}" class="rsf-btn btn-small rsf-btn-ghost color-1 h7 flex items-center gap1"
        style="padding: 0.5rem 0.75rem; font-size: 0.75rem">
       <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="svg-color-1" style="width: 1rem; height: 1rem; stroke: var(--rsf-2022-color-1)">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
</svg>

        Phone
      </a>`
        : ""
    }
    </div>
    <div class="h1px col-12 bg-color-12 my2 px2 border-box" style="background-clip: content-box"></div>
    <div class="flex gap1 items-center overflow-x-scroll no-scrollbar px2">
      <a target="_blank" rel="noreferrer noopener" href="https://www.google.com/maps/dir/?api=1&origin=${userPosition.lat}%2C${userPosition.lng}&destination=${store.mapCoordsLatitude}%2C${store.mapCoordsLongitude}&travelmode=car" class="rsf-btn rsf-btn-std bg-color-1 color-white open-sans-regular h7"
        style="font-size: 0.75rem; padding: 0.75rem 1rem">Get directions
      </a>
      <div id="streetview-btn" data-lat="${store.mapCoordsLatitude}" data-lng="${store.mapCoordsLongitude}" class="gmiw-test rsf-btn rsf-btn-std bg-color-1 color-white open-sans-regular h7"
        style="font-size: 0.75rem; padding: 0.75rem 1rem">
        Street view
      </div>
    </div>
  </div>
</div>`;
}
